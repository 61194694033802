<template>
    <div class="foots">
        <div class="serve">
            <div>
                <h3>{{$t('footer.lianxi.title')}}</h3>
                <div class="div">{{lianxi.name}}</div>
                <div class="div">{{$t('footer.lianxi.tel')}}：{{lianxi.tel}}</div>
                <div class="div">{{$t('footer.lianxi.email')}}：{{lianxi.eMail}}</div>
                <div class="div">{{$t('footer.lianxi.time')}}：{{lianxi.workTime}}</div>
            </div>
            <div>
                <h3>{{$t('footer.about.title')}}</h3>
                <div class="div" @click="$router.push('/About/brand')">{{$t('footer.about.ppgs')}}</div>
               <!-- <div class="div">{{$t('footer.about.gmqd')}}
                    @click="$router.push('/About/introduction')"
                    <div class="scloor" @click.stop="navTo">
                        天猫旗舰店
                    </div>
                    <div class="scloor" @click.stop="navTo">
                        京东旗舰店
                    </div>
                    <div class="scloor" @click.stop="navTo">
                        线下店铺
                    </div>
                    <div class="scloor" @click.stop="navTo">
                        Amazor
                    </div> 
                </div> -->
                <div class="div" @click="$router.push('/About/privacy')">{{$t('footer.about.ystk')}}</div>
                <!-- <div class="div" @click="$router.push('/About/privacy')">{{$t('footer.about.sytk')}}</div> -->
            </div>
            <div>
                <h3>{{$t('footer.aftersale.title')}}</h3>
                <div class="div" @click="$router.push('/maintain')">{{$t('menu.weixiu[0]')}}</div>
                <div class="div" @click="$router.push('/inform')">{{$t('menu.weixiu[1]')}}</div>
                <!-- <div class="div" @click="$router.push('/charge')">{{$t('menu.weixiu[2]')}}</div> -->
                <div class="div" @click="$router.push('/commonProblem')">{{$t('footer.aftersale.question')}}</div>
                <!-- <div class="div" @click="$router.push('/vipSystem')">{{$t('menu.vipzhidu')}}</div>
                <div class="div" @click="navToA">{{$t('footer.lianxi.title')}}</div> -->
            </div>
        </div>
        <!-- <div class="email">
            <h3>{{$t('footer.join')}}</h3>
            <el-input style="width:350px;" :placeholder="$t('footer.emailAdrs')" v-model="email">
                <el-button slot="append" class="elInput" :loading="loading" @click="joinUs">{{$t('footer.dingyue')}}</el-button>
            </el-input>  
        </div> -->
        <div class="QRCode">
            <img src="../../assets/ewm.png" alt="">
            <!-- <img src="../../assets/baidu.png" alt=""> -->
        </div>
    </div>
</template>

<script>
import {joinUs,getCompanyInfo} from '@/api/main.js'
export default {
    data(){
        return{
            email:'',
            loading:false,
            lianxi:{

            }
        }
    },
    mounted(){
        this.getFooter();
    },
    methods:{
        getFooter(){
            getCompanyInfo({}).then(res=>{
                 if (this.isChinese()) {
                        this.lianxi = res.data
                    }else{
                        this.lianxi = {
                            ...res.data,
                            name:res.data.nameEng,
                            workTime:res.data.workTimeEng
                        }
                    }
            })
        },
        navTo(){
            window.open('https://www.baidu.com')
        },
        navToA(){
           // window.location.href = 'http://workerman.winderinfo.com/addons/kefu/index/index'
            // window.open('http://workerman.yocamily.com/addons/kefu/index/index')
            window.open('https://admin.akomapa.online/addons/kefu/index/index')
        },
        joinUs(){
            var reg = new RegExp("^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\\.[a-zA-Z0-9_-]+)+$");
            if(!reg.test(this.email)){
                if (this.isChinese()) {
                    this.$message({
                        message: '邮箱有误',
                        type: 'warning'
                    });
                } else {
                    this.$message({
                        message: 'Incorrect email address',
                        type: 'warning'
                    });
                }
                return
            }
            this.loading = true
            joinUs({
                email:this.email
            }).then(res=>{
                if(res.code==0){
                    if (this.isChinese()) {
                        this.$message({
                            message: '加入成功',
                            type: 'success'
                        });
                    } else {
                        this.$message({
                            message: 'successfully added',
                            type: 'success'
                        });
                    }
                    this.loading = false
                }else{
                    this.showMessage();
                }
            })
        }
    }
};
</script>



<style lang='scss' scoped>
.foots{
    background: #F9F9F9;
    padding: 95px 0px;
}
.foots,.serve{
    display: flex;
    justify-content: space-around;
}
.serve{
    width: 800px;
    h3{
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        line-height: 25px;
        margin-bottom: 15px;
        color: #333333;
    }
   .div{
      //  font-size: 15px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #909399;
        line-height: 35px;
        cursor:pointer;
        .scloor{
            padding: 0px 15px;
            line-height: 25px;
            color: #aaaaaa;
        }
    }
   
}
.email{
    h3{
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        line-height: 25px;
        margin-bottom: 15px;
        color: #333333;
    }
    .elInput{
        width: 100px;
        height: 40px;
        background: #414042 !important;
        //border:0px 7px 7px 0px;
        color: #FFFFFF;
        border-radius: 0px;
        border:none;
    }
    .elInput:hover{
        background: rgb(36, 36, 36) !important;
        color: #000;
        background-color: transparent !important;
        opacity: 1 ;
        border:1px solid #000;
        box-shadow: none;
    }
}
.QRCode{
   width: 27%;
    display: flex;
    align-items: center;
    img{
        padding: 10px;
        border:1px solid #000;
        margin-right: 40px;
        width: 150px;
        height: 150px;
    }
}

</style>
