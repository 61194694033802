<template>
    <div>
        <div class="head">
            <img @click="navTo({ path: '/index' })" src="../../../public/imgs/head/logo.png" alt="">
            <div class="el-menu" @click="hidderChat">
                <div class="el-menu-demo">
                    <div class="el-submenu" @mouseover="submenuActive = 1" @mouseleave="submenuActive = 0"
                        :class="submenuActive == 1 ? 'el-submenuActive' : ''">
                        <span>{{ $t('menu.fenlei') }}</span>
                        <i class="el-icon-arrow-down"></i>
                        <i class="el-icon-arrow-up"></i>
                        <div class="sub-menus">
                            <div class="goods-classss">
                                <div class="clou" v-for="(item, index) of [...goodsClassList]" :key="index">
                                    <div class="title">{{ isChinese() ? item.name : item.nameEng }}</div>
                                    <div class="item" v-for="(items, indexs) of item.sonList" :key="indexs"
                                        @click="navToproductSku(items)">{{ isChinese() ? items.name : items.nameEng }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="el-submenu">
                        <span @click="navTo({ path: '/activity' })">{{ $t('menu.huodong') }}</span>
                    </div> -->
                    <!-- <div class="el-submenu" @mouseover="submenuActive = 2" @mouseleave="submenuActive = 0"
                        :class="submenuActive == 2 ? 'el-submenuActive' : ''">
                        <span>{{ $t('menu.shouhao') }}</span>
                        <i class="el-icon-arrow-down"></i>
                        <i class="el-icon-arrow-up"></i>
                        <div class="menu-items">
                            <div style="margin-top: 25px;" @click="navTo({ path: '/maintain' })">{{ $t('menu.weixiu[0]') }}</div>
                            <div @click="navTo({ path: '/inform' })">{{ $t('menu.weixiu[1]') }}</div>
                            <div @click="navTo({ path: '/charge' })">{{ $t('menu.weixiu[2]') }}</div>
                        </div>
                    </div> -->
                    <div class="el-submenu" @mouseover="submenuActive = 3" @mouseleave="submenuActive = 0"
                        :class="submenuActive == 3 ? 'el-submenuActive' : ''">
                        <span>{{ $t('menu.fulanm') }}</span>
                        <i class="el-icon-arrow-down"></i>
                        <i class="el-icon-arrow-up"></i>
                        <div class="sub-menus">
                            <div class="serve">
                                <div class="text">
                                    <div class="h3">{{ serveceColumn.title }}</div>
                                    <span>{{ serveceColumn.content }}</span>
                                </div>
                                <div class="pic" @click="navTo({ path: '/mine/logistics' })">
                                    <img :src="serveceColumn.picture1" alt="">
                                    <div>{{ $t('footer.aftersale.wlcx') }}</div>
                                </div>
								<!--  @click="navTo({ path: '/vipSystem' })" -->
                                <div class="pic" @click="navTo({ path: '/commonProblem' })">
                                    <img :src="serveceColumn.picture2" alt="">
                                    <div>{{ $t('footer.aftersale.question') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="el-submenu" @mouseover="submenuActive = 4" @mouseleave="submenuActive = 0"
                        :class="submenuActive == 4 ? 'el-submenuActive' : ''">
                        <span>{{ $t('menu.guanyuwomen') }}</span>
                        <i class="el-icon-arrow-down"></i>
                        <i class="el-icon-arrow-up"></i>
                        <div class="sub-menus">
                            <div class="serve">
                                <div class="text">
                                    <div class="h3">{{ AboutUs.title }}</div>
                                    <span>{{ AboutUs.content }}</span>
                                </div>
                                <div class="pic" @click="navTo({ path: '/About/introduction' })">
                                    <img :src="AboutUs.picture1" alt="">
                                    <div>{{ $t('menu.about[0]') }}</div>
                                </div>
                                <div class="pic" @click="navTo({ path: '/About/brand' })">
                                    <img :src="AboutUs.picture2" alt="">
                                    <div>{{ $t('menu.about[1]') }}</div>
                                </div>
                                <div class="pic" @click="navTo({ path: '/blogs' })">
                                    <img :src="AboutUs.picture3" alt="">
                                    <div>{{ $t('menu.boke') }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="func">
                <div class="funcs">
                    <el-dropdown @command="handleCommanduser">
                        <span class="el-dropdown-link">
                            <img src="../../../public/imgs/head/user.png" @click="toGoMint()" alt="" />
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="index" v-for="(item, index) of menuList" :key="index">
                                {{ item }}
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <img style="margin:0 0 0 32px" @click="closeChat" src="../../../public/imgs/head/cat.png" alt="">
                    <img style="margin:0 32px" @click="handleCollect" src="@/assets/img/collect_icon.png" alt="">
                    <div class="lang">
                       
                        <el-dropdown @command="handleCommand" trigger="click" :tabindex="1">
                            <span class="el-dropdown-link">
                                <img src="../../../public/imgs/head/lang.png" alt="" />
                                {{ lang }}<i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="index" v-for="(item, index) of langArr" :key="index">
                                    <div style="display: flex;align-items: center;">
                                        <img style="width: 20px;height: 20px;margin-right: 10px;" v-if="item.img"
                                            src="../../../public/imgs/head/active.png" alt=" " />
                                        <img style="width: 20px;height: 20px;margin-right: 10px;" v-else
                                            src="../../../public/imgs/head/on-pahc.png" alt="">
                                        <span>{{ item.title }}</span>
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
                <div class="search">
                    <el-autocomplete v-model="textKey" :fetch-suggestions="querySearchAsync"
                        :placeholder="$t('menu.shareText')" @select="handleSelect">
                        <template slot-scope="{ item }">
                            <div class="slotScope">
                                <span>{{ item.value }}</span>
                                <i @click.stop="sherchDelt(item)" class="el-icon-close"></i>
                            </div>
                        </template>
                    </el-autocomplete>
                    <img @click="search" class="searchimg" src="../../../public/imgs/head/search.png" alt="">
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { listGoodsClassify, getServiceColumn, getAboutUs } from '@/api/main.js'
import $bus from '@/util/bus.js'
import { mapState ,mapMutations} from 'vuex';
export default {
    name: 'topHead',
    inject: ['reload'],
    components: {},
    props: {
        closeChat: {//点击购物车打开
            type: Function,
            default() {
                return null
            }
        },
        hidderChat:{//点击任意页头购物车关闭 
            type: Function,
            default(){
                return null
            }
        }
    },
    data() {
        return {
            lang: '中文',
            langArr: [
                { title: 'English', img: false },
                { title: '中文', img: true }
            ],
            restaurants: [],
            textKey: '',
            timeout: null,
            goodsClassList: [],//分类
            serveceColumn: {},//获取服务栏目信息
            AboutUs: {},//获取关于我们嘞栏目信息
            submenuActive:-1,
            menuList: [],

        }
    },
    computed:{...mapState(['token','userInfo'])},
    mounted() {
        const locale = localStorage.getItem('lang');
        if (locale) {
            this.handleCommand(locale == 'zh' ? 1 : 0)
        }
        this.restaurants = this.loadAll();
        this.getlistGoodsClassify();
        this.getServiceColumn();//获取服务栏目信息
        this.getAboutUs();//获取关于我们嘞栏目信息
        let token = localStorage.getItem('token');
        if (token) {
            this.menuList = this.$t('mine.menuList1')
        } else {
            this.menuList = this.$t('mine.menuList')
        }
    },
    
    methods: {
        handleCollect(){
            // $bus.$emit('updateRouter')
            this.navTo({path:'/mine/favorite'})
        },
        ...mapMutations(['logOut']),
        getlistGoodsClassify() {//获取商品分类
            listGoodsClassify({}).then(res => {
                if (res.code == 0) {
                    this.goodsClassList = res.data
                } else {
                    this.showMessage();
                }
            })
        },
        getServiceColumn() {//获取服务栏目信息
            getServiceColumn({}).then(res => {
                if (res.code == 0) {
                    if (this.isChinese()) {
                        this.serveceColumn = res.data
                    } else {
                        this.serveceColumn = {
                            ...res.data,
                            title: res.data.titleEng,
                            content: res.data.contentEng
                        }
                    }

                } else {
                    this.showMessage();
                }
            })
        },
        getAboutUs() {
            getAboutUs({}).then(res => {
                if (res.code == 0) {
                    if (this.isChinese()) {
                        this.AboutUs = res.data
                    } else {
                        this.AboutUs = {
                            ...res.data,
                            title: res.data.titleEng,
                            content: res.data.contentEng
                        }
                    }
                } else {
                    this.showMessage();
                }
            })
        },
        navToproductSku(items) {//根据商品分类去筛查商品
            this.submenuActive = 0
            if (this.$route.path.indexOf('/productSku') >= 0) {
                $bus.$emit('productSku', { id: items.id })
            } else {
                this.$router.push('/productSku?id=' + items.id)
            }

        },
        toGoMint() {
            let toke = localStorage.getItem('token');

            this.submenuActive = 0
            if (toke) {
                this.$router.push({ path: '/mine/userInfo' })
            } else {
                this.$router.push({ path: '/login' })
            }


        },
        navTo(obj) {
			console.log(obj)
            this.submenuActive = 0
            this.$router.push(obj.path)
        },
        handleCommanduser(e) {
            if (e == 6) {//退出登录/登录
                let token = localStorage.getItem('token');
                if(token){
                    this.logOut();
                    this.$router.push('/login')
                    this.reload();
                }else{
                    this.$router.push('/mine/userInfo')
                }
                return
            }
            var path = {
                0: '/mine/userInfo',
                1: '/mine/favorite',
                2: '/mine/shoppingCart',
                3: '/mine/orderList',
                4: '/mine/vipGrade',
                5: '/mine/message',
                6: '/mine/setingPassword',
            }
            console.log(path[e])
            this.$router.push(path[e])
            // if (this.$route.path.indexOf('/mine') >= 0) {
                // $bus.$emit('updateRouter')
            // }
           this.hidderChat();//点击我的列表购物车关闭一下
        },
        handleCommand(e) {
            this.langArr.forEach((item, index) => {
                if (index == e) {
                    this.lang = item.title
                    item.img = true
                    this.$i18n.locale = 'zh'
                    localStorage.setItem('lang', 'zh')
                } else {
                    item.img = false
                    this.$i18n.locale = 'en'
                    localStorage.setItem('lang', 'en')
                }
            })
        },
        sherchDelt(row) {
            let searchList = JSON.parse(localStorage.getItem('searchList'));
            let arr = searchList.filter(item => {
                if (item.value !== row.value) {
                    return item
                }
            })
            localStorage.setItem('searchList', JSON.stringify(arr))
            this.reload();
        },
        loadAll() {
            let searchList = JSON.parse(localStorage.getItem('searchList'));
            return searchList ? searchList : []
        },
        querySearchAsync(queryString, cb) {

            var restaurants = this.restaurants;
            var results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                cb(results);
            }, 10 * Math.random());
        },
        createStateFilter(queryString) {
            return (state) => {
                return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        search() {
            if (!this.textKey) return//没有搜索内容
            if (this.$route.path == '/search') {//如果是搜索页面直接更新搜索内容
                $bus.$emit('search', { textKey: this.textKey })
            } else {//如果不是带起内容跳转
                this.$router.push({ name: 'search', params: { textKey: this.textKey } })
            }
            //记录搜索内容去重等等
            let searchList = JSON.parse(localStorage.getItem('searchList'));
            if (searchList == null) {
                localStorage.setItem('searchList', JSON.stringify([{ value: this.textKey }]))
            } else {
                var isId = 0;//记录有没有重复向
                searchList.map(item => {
                    if (item.value == this.textKey) {
                        isId += 1
                    }
                })
                if (isId == 0) {//去重
                    searchList.push({ value: this.textKey })
                    localStorage.setItem('searchList', JSON.stringify(searchList))
                }
            }
            this.hidderChat();//点击搜索购物车关闭一下
        },
        handleSelect() {
            if (this.$route.path == '/search') {
                $bus.$emit('search', { textKey: this.textKey })
            } else {
                this.$router.push({ name: 'search', params: { textKey: this.textKey } })
            }
            this.restaurants = this.loadAll();
        },

    }
};
</script>

<style lang='scss'>
.head {
    margin: 0 auto;
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 15px 0px;

    img {
        width: 248px;
        height: 78px;
    }

    .el-menu {
        width: 380px;
		border: none;

        .el-menu-demo {
            display: flex;
            justify-content: space-around;
            align-items: flex-end;
           
            .el-submenu {
                //height: 124px;
                text-align: center;
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                width: 120px;
                overflow: hidden;
                vertical-align: bottom;
                position: relative;
               
                span {
                    cursor: pointer;
                }
            }

            .el-icon-arrow-up {
                display: none;
            }

            // .el-submenu:hover {
            //     .el-icon-arrow-down {
            //         display: none;
            //         color: #909399;
            //     }

            //     .el-icon-arrow-up {
            //         display: inline;
            //     }

            //     .sub-menus {
            //         display: block;
            //         height: 350px;
            //         transition: all .3s ease .3s;
            //     }
            //     .menu-items{

            //         height: 152px;
            //         transition: all .3s ease .3s;
            //     }
            // }
            .el-submenuActive {
                .el-icon-arrow-down {
                    display: none;
                    color: #909399;
                }

                .el-icon-arrow-up {
                    display: inline;
                }

                .sub-menus {
                    display: block;
                    height: 350px;
                    transition: all .3s ease .3s;
                }

                .menu-items {

                    height: 152px;
                    transition: all .3s ease .3s;
                }
            }

            .sub-menus {

                position: fixed;
                top: 120px;
                left: 0vw;
                width: 100vw;
                height: 0px;
                background: #F0F0F0;
                transition: all .3s ease .3s;
                overflow: hidden;
            }

            .goods-classss {
                display: flex;
                justify-content: center;
                margin: 0 auto;
                padding-top: 40px;
                cursor: pointer;
                width: 1200px;
                .clou {
                  //  margin-left: 220px;
                  margin: 0px 20px;
                    width: 220px;
                    .title {
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 600;
                        color: #000000;
                        margin-bottom: 32px;
                    }

                    .item {
                        font-size: 15px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        margin-top: 20px;
                    }
                }
            }

            .serve {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 350px;

                // padding: 0px 16%;
                .text {
                    width: 380px;
                   // height: 280px;
                    padding: 30px;

                    .h3 {
                        font-size: 19px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 500;
                        color: #333333;
                        margin-bottom: 20px;

                    }

                    span {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #444444;

                        word-wrap: break-word;
                        word-break: break-all;
                        overflow: hidden;

                    }

                    margin-right: 15px;
                }

                .pic {
                    margin-right: 30px;

                    img {
                        width: 300px;
                        height: 240px;
                    }

                    div {
                        text-align: center;
                        margin-top: 18px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }
                }
            }

            .menu-items {
                position: fixed;
                top: 120px;
                width: 195px;
                height: 0px;
                background: #F2F2F2;
                overflow: hidden;
                transition: all .3s ease .3s;

                div {
                    padding: 0px 10px;
                    font-size: 15px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    line-height: 21px;
                    margin-bottom: 20px;
                    cursor: pointer;
                    text-align: left;
                }
            }
        }
    }

    .func {
		display: flex;
		margin-left: 30px;
        .funcs {
            display: flex;
            justify-content: space-around;
            align-items: center;
            position: relative;

            .lang {
                width: 110px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            img {
                width: 35px;
                height: 35px;
            }
        }

        .search {
            position: relative;
            /* margin-top: 20px; */

            .searchimg {
                position: absolute;
                right: 10px;
                top: 5px;
                width: 17px;
                height: 17px;
            }
			.el-input__inner{
				width: 268px;
				height: 28px;
			}
        }
    }
}

.el-dropdown-link {
    cursor: pointer;
    color: #333333 !important;
    img{
        vertical-align: middle;
       
    }
}

.el-icon-arrow-down {
    font-size: 12px;
}

.slotScope {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
        white-space: nowrap;
        /* 超出盒子部分隐藏 */
        overflow: hidden;
        /* 文本超出的部分打点显示 */
        text-overflow: ellipsis;
    }
}</style>