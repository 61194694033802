<template>
  <div class="bodys">
    <div class="top">
      <topHead :closeChat="closeChat" :hidderChat="hidderChat"></topHead>
    </div>

    <!-- ,/ -->
    <keep-alive include="blogs">
      <router-view />
    </keep-alive>
    <div class="postaber">
      <img @click="toGo()" src="../../public/imgs/facebook.png" alt="">
      <img @click="toGo()" src="../../public/imgs/ins.png" alt="">
      <img @click="toGo()" src="../../public/imgs/serve.png" alt="">
      <img @click="scrollToTop()" src="../../public/imgs/up.png" alt="">
    </div>
    <footers></footers>
    <div class="shoppingTrolley" v-if="showChat" @click.stop="hidderChat">
      <div class="cart">
        <div class="head-chat">
          <span>{{ $t('mine.menuList[2]') }}</span>
          <i @click.stop="closeChat" class="el-icon-close"></i>
        </div>
        <div class="cart-list">
          <div class="list" v-for="(item, index) of shoppingCartList" :key="index">
            <img :src="item.cover" alt="">
            <div class="right">
              <div class="detaul">
                <div class="name">{{ isChinese() ? item.name : item.nameEng }}</div>
                <span>¥{{ item.price }}</span>
              </div>
              <div class="sku">
                <!-- 颜色： <span>紫色</span> -->
                {{ isChinese() ? item.keyName : item.keyNameEng }}
              </div>
              <div class="addDelceCact">
                <span class="del" @click.stop="deleteCar(item, index)">{{ isChinese() ? '删除' : 'delete' }}</span>
                <div class="input-number">
                  <div class="font" @click.stop="subtract(item, index)">-</div>
                  <div>{{ item.number }}</div>
                  <div class="font " @click.stop="add(item, index)">+</div>
                </div>
              </div>
            </div>
          </div>
          <empty v-if="shoppingCartList.length==0" :title="isChinese() ? '购物车为空':'Shopping cart is empty'"></empty>
        </div>
        <div class="total">
          <span>
            {{ $t('mine.elses.heji') }}：
          </span>
          <span>
            ¥{{ totalPic }}
          </span>
        </div>
        <div class="button" @click.stop="confirmOrder">
          {{ $t('button.lijitijao') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topHead from "./head/index.vue";
import footers from './footer/index.vue';
import $bus from '@/util/bus.js'
import { listUserShoppingCart, updateShoppingCart, deleteShoppingCart } from '@/api/main.js'
import empty from '@/components/empty.vue'
export default {
  name: 'nav-home',
  components: { topHead, footers,empty },
  data() {
    return {
      showChat: false,
      shoppingCartList: [],//购车
      isLoding: true,
      totalPic: 0,//商品总价
    }
  },
  mounted() {
    //this.listUserShoppingCart()//获取购物车
    $bus.$on('updateChat', this.updataListUserShopingCart)//更新购物车
    
  },
  methods: {
    hidderChat(){
      this.showChat = false
    },
    toGo(){
      window.open('http://www.baidu.com') 
    },
    //提交订单
    confirmOrder(){
      if(this.shoppingCartList.length==0){
          this.$message({
              type: 'warning',
              message: this.isChinese() ? '购物车为空':'Shopping cart is empty'
            });
        return
      }
      this.showChat = false
      this.$router.push({name:'submit',params:{cartList:JSON.stringify(this.shoppingCartList)}})
    },
    deleteCar(item, index) {
      this.showChat = false
      var obj = {}
      if (this.isChinese()) {
        obj = {
          title: '您确定要删除？',
          del: '删除',
          confirm: '确定',
          cancel: '取消'
        }
      } else {
        obj = {
          title: 'You are sure you want to delete？',
          del: 'delete',
          confirm: 'confirm',
          cancel: 'cancel'
        }
      }
      this.$confirm(obj.title, obj.del, {
        confirmButtonText: obj.confirm,
        cancelButtonText: obj.cancel,
        type: 'warning'
      }).then(() => {
        this.showChat = true
        this.deleteShoppingCart(item,index)
      }).catch(() => {
        this.showChat = true
      });

    },
    //删除购物车商品
    deleteShoppingCart(item,index){
      deleteShoppingCart({
          shoppingCartId: item.id
        }).then(res => {
          if (res.code == 0) {
            this.$message({
              type: 'success',
              message: this.isChinese() ? '删除成功':'Successfully delete'
            });
            this.shoppingCartList.splice(index, 1)
            this.updateTotalPic();//更新总价
          } else {
            this.showMessage();
          }
        })
    },
    subtract(item, index) {//数量减
      if (!this.isLoding) return;
     
      if(item.number==1){//如果数量为1在删除那就清除这个商品
        this.deleteShoppingCart(item,index);
        return
      }
      this.isLoding = false
      var nber = item.number;
      updateShoppingCart({
        id: item.id,
        number: nber - 1
      }).then(res => {
        this.isLoding = true;
        if (res.code == 0) {
          this.shoppingCartList[index].number = nber - 1;
          this.updateTotalPic();//更新总价
        } else {
          this.showMessage();
        }
      })
    },
    add(item, index) {//数量加
      if (!this.isLoding) return;
      this.isLoding = false
      var nber = item.number;
      updateShoppingCart({
        id: item.id,
        number: nber + 1
      }).then(res => {
        this.isLoding = true;
        if (res.code == 0) {
          this.shoppingCartList[index].number = nber + 1;
          this.updateTotalPic();//更新总价
        } else {
          this.showMessage();
        }
      })
    },
    updataListUserShopingCart(){//
      this.listUserShoppingCart()
      this.showChat = true
    },
    listUserShoppingCart() {//获取购物车
      listUserShoppingCart({}).then(res => {
        if (res.code == 0) {
          this.shoppingCartList = res.data
          this.updateTotalPic()
        } else {
          this.showMessage();
        }
      })
    },
    updateTotalPic() {//计算价格
      let bigPrice = 0
      this.shoppingCartList.find((item) => {
        bigPrice += (item.price * item.number) + item.freight //freight 运费不知道加不加
      })
      this.totalPic = bigPrice
    },
    closeChat() {
      let token = localStorage.getItem('token');
      if(token){
        this.listUserShoppingCart();
        this.showChat = !this.showChat
      }else{
        this.$router.push('/login')
      }
      
    },
    scrollToTop() {
      window.scrollTo(0, 0)
    }
  }
};
</script>



<style lang='scss' scoped>
.bodys {
  background-color: #ffff;

  .top {
    background-color: #ffff;
    position: sticky;
    top: 0px;
    z-index: 100;
  }
}

.shoppingTrolley {
  right: 0px;
  width: 100vw;
  height: 90vh;
  position: fixed;
  z-index: 10;
  top: 128px;
  background: rgba(0, 0, 0, .7);

  .cart {
    width: 450px;
    height: 664px;
    background: #F9F9F9;
    position: absolute;
    right: 0px;
    top: 0px;

    .head-chat {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 30px;
      border-bottom: 1px solid #ECECEC;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      box-sizing: border-box;

      i {
        font-size: 25px;
      }
    }

    .cart-list {
      padding: 0px 30px;
      height: 435px;
      overflow: hidden;
      overflow-y: scroll;

      .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ECECEC;
        padding: 25px 0px;

        img {
          width: 110px;
          height: 138px;
          background: #FFFFFF;
        }

        .right {
          width: 250px;

          .detaul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px 0px;
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;

          }

          .sku {
            padding: 12px 0px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #777777;

            span {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
            }
          }

          .addDelceCact {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 15px 0px;

            .del {
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #AAAAAA;
              cursor: pointer;
            }

            .input-number {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 80px;
              height: 30px;
              background: #F2F2F2;
              padding: 0px 5px;
              cursor: pointer;

              div {
                font-size: 16px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                line-height: 22px;

              }

              .font {
                font-size: 25px;
              }
            }

          }
        }
      }
    }

    .total {
      border-top: 1px solid #000000;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 30px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      box-sizing: border-box;
    }

    .button {
      width: 390px;
      background: #000000;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #dddd;
      line-height: 25px;
      padding: 15px 0px;
      text-align: center;
      margin: 15px auto;
      border: 1px solid #000;
      cursor: pointer;

    }

    .button:hover {
      color: #000000;
      background: #ffff;
      border: 1px solid #000;
      transition: color 1s ease .1s, background 1s ease .1s;
    }
  }
}

.postaber {
  position: fixed;
  top: 50%;
  right: 110px;
  margin-top: -110px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 44px;
    height: 44px;
    margin-bottom: 8px;
  }

  img:last-child {
    width: 56px;
    height: 56px;
  }
}</style>